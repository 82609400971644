"use client";

import type {
  CategoryWithItemIdsType,
  ItemChoiceGroupTreeType,
  ItemType,
} from "@lifizy/domain-next";
import { ItemChoiceTreeType } from "@lifizy/domain-next";
import { createContext, useContext, useOptimistic } from "react";
type DataType = {
  groupId: string;
  items: ItemType[];
  categories: CategoryWithItemIdsType[];
  itemChoiceGroups: ItemChoiceGroupTreeType[];
};

interface AddItemType {
  type: "addItem";
  category: CategoryWithItemIdsType;
  item: ItemType;
}
interface DeleteItemType {
  type: "deleteItem";
  itemId: string;
}
interface UpdateItemType {
  type: "updateItem";
  item: ItemType;
}

interface UpdateCategoryType {
  type: "updateCategory";
  item: ItemType;
  category: CategoryWithItemIdsType;
}
interface AddItemChoiceType {
  type: "addItemChoice";
  choiceGroupId: string;
  choice: ItemChoiceTreeType;
}
interface DeleteItemChoiceType {
  type: "deleteItemChoice";
  choiceGroupId: string;
  choiceId: string;
}
interface UpdateItemChoiceType {
  type: "updateItemChoice";
  choiceGroupId: string;
  choice: ItemChoiceTreeType;
}
type ActionType =
  | AddItemType
  | DeleteItemType
  | UpdateItemType
  | UpdateCategoryType
  | AddItemChoiceType
  | DeleteItemChoiceType
  | UpdateItemChoiceType;

type WebDataContextType = {
  oData: DataType;
  updateState: (action: ActionType) => void;
  getCategoryItems: (categoryId: string) => ItemType[];
  getItemChoiceGroup: (itemId: string | null) => ItemChoiceGroupTreeType | null;
  getItem(itemId: string): ItemType | undefined;
  getGroupId(): string;
};

export const WebDataContext = createContext<WebDataContextType | undefined>(undefined);

export default function WebDataProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: DataType;
}) {
  const [oData, updateState] = useOptimistic(data, (state: DataType, action: ActionType) => {
    if (action.type === "updateCategory") {
      const updatedCats = state.categories.map((c) =>
        c.id === action.category!.id ? { ...c, ...action.category } : c
      );
      return { ...state, categories: updatedCats };
    }
    if (action.type === "updateItem") {
      const items = state.items.map((i) =>
        i.id === action.item!.id ? { ...i, ...action.item } : i
      );
      return { ...state, items };
    }
    if (action.type === "addItem" && action.item && action.item.id) {
      console.log("add item", state, action);
      const categories = state.categories.map((c) => {
        return c.id === action.category.id
          ? { ...c, itemIds: [...c.itemIds, action.item!.id!] }
          : c;
      });
      return { ...state, items: [...state.items, action.item], categories };
    }
    if (action.type === "deleteItem" && action.itemId) {
      console.log("delete item", state, action);
      const categories = state.categories.map((c) => {
        return { ...c, itemIds: c.itemIds.filter((i) => i !== action.itemId) };
      });
      const items = state.items.filter((i) => i.id !== action.itemId);
      return { ...state, items, categories };
    }
    if (action.type === "updateItemChoice") {
      const choiceGroup = state.itemChoiceGroups.find((c) => c.id === action.choiceGroupId);
      if (!choiceGroup) return state;
      const newChoiceGroup = {
        ...choiceGroup,
        choices: choiceGroup.choices.map((c) =>
          c.id === action.choice.id ? { ...c, ...action.choice } : c
        ),
      };
      const itemChoiceGroups = state.itemChoiceGroups.map((c) =>
        c.id === action.choiceGroupId ? newChoiceGroup : c
      );
      return { ...state, itemChoiceGroups };
    }
    return state;
  });
  const getItem = (itemId: string) => {
    return oData.items.find((i) => i.id === itemId);
  };
  const getCategoryItems = (categoryId: string) => {
    const category = oData.categories.find((c) => c.id === categoryId);
    if (!category) return [];
    return oData.items.filter((i) => category.itemIds.includes(i.id!));
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getItemChoiceGroup = (itemId: string | null) => {
    if (!oData.itemChoiceGroups || oData.itemChoiceGroups.length === 0) return null;
    return oData.itemChoiceGroups[0];
  };
  const getGroupId = () => {
    return oData.groupId;
  };
  return (
    <WebDataContext.Provider
      value={{ oData, updateState, getCategoryItems, getItemChoiceGroup, getItem, getGroupId }}>
      {children}
    </WebDataContext.Provider>
  );
}

export function useWebDataContext() {
  const context = useContext(WebDataContext);
  if (context === undefined) {
    throw new Error("useWebDataContext must be used within a WebDataContext Provider");
  }
  return { ...context };
}
