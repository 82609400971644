export const formatPrice = (price: number | undefined | null, digit?: number) => {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
    maximumFractionDigits: digit !== undefined ? digit : 2,
  }).format(price ? price / 100 : 0);
};

export const getPriceAsNumber = (price: string): number => {
  return Math.abs(parseFloat(price.replace("€", "").replace(",", ".").replace(" ", "")) * 100);
};
