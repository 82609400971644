"use client";
import React, { useEffect, useState } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";

interface BasicContentEditableProps {
  html: string;
  editOn: boolean;
  onChange: (value: string) => void;
}
let timer: NodeJS.Timeout | null = null;
const TIME_TO_DEBOUNCE = 1000;
function BasicContentEditable({
  html,
  editOn,
  onChange,
  ...props
}: BasicContentEditableProps & React.HTMLAttributes<HTMLDivElement>): React.ReactElement {
  const [isFocus, setIsFocus] = useState(false);
  const [localHtml, setLocalHtml] = useState(html);
  const handleChange = (e: ContentEditableEvent) => {
    setLocalHtml(e.target.value);
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      onChange(e.target.value);
    }, TIME_TO_DEBOUNCE);
  };
  const onBlur = (): void => {
    setIsFocus(false);
  };
  const onFocus = (): void => {
    setIsFocus(true);
  };
  useEffect(() => {
    setLocalHtml(html);
  }, [html]);
  return (
    <div className="relative w-fit m-auto text-balance text-center">
      {/* {!isFocus && editOn && (
        <BiSolidMessageSquareEdit className="absolute right-[-8px] top-[0px] w-3 h-3" />
      )} */}
      <ContentEditable
        className={`${editOn ? (isFocus ? "outline" : "outline-dashed outline-gray-400") : ""} px-2 outline-gray-800 outline-1 `}
        disabled={!editOn}
        html={localHtml}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />
    </div>
  );
}

export default BasicContentEditable;
