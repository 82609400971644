import BasicContentEditable from "@/components/edit/basic-content-editable";
import { formatPrice, getPriceAsNumber } from "@/functions/dataFormat";
import { useEditUiContext } from "@/providers/edit/edit-ui-provider";
import {
  useOrderManagerDispatch,
  useSettingOption,
} from "@/providers/order/order-manager-provider";

import { useWebDataContext } from "@/providers/web-data/web-data-provider";
import { ItemChoiceTreeType, ItemOptionType } from "@lifizy/domain-next";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@lifizy/ui";
import { useState } from "react";
import { BsFileMinusFill, BsFilePlusFill } from "react-icons/bs";
import { FcDataConfiguration } from "react-icons/fc";
import { HiOutlineTrash } from "react-icons/hi";

interface OptionViewProps {
  choice: ItemChoiceTreeType;
  option: ItemOptionType;
  img?: string;
  onNext: () => void;
  multi: boolean;
  onRemove?: (choiceId: string) => void;
  onChange?: (option: ItemOptionType) => void;
}
export default function OptionView({
  choice,
  option,
  img,
  multi,
  onRemove,
  onChange,
}: OptionViewProps) {
  const [amount, setAmount] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { getItem } = useWebDataContext();
  const { active, webAdminForm } = useEditUiContext();
  const item = option.item ? option.item : option.itemId ? getItem(option.itemId) : null; // TODO: always get item from global state
  const settingoption = useSettingOption(choice.id, option.id);
  const dispatchOrderMng = useOrderManagerDispatch();
  if (!item) return <div>Erreur no item defined</div>;
  function onAction(amountAction: number) {
    const newAmount = amount + amountAction;
    setAmount((prev) => prev + amountAction);
    dispatchOrderMng({
      type: "updateCurrentSetting",
      optionId: option.id,
      choiceId: choice.id,
      amount: newAmount,
      unitPrice: item?.price ? item.price : 0,
      label: item?.title ? item.title : "No label",
      multi: multi,
    });
    //if (!multi) onNext();
  }
  // useEffect(() => {
  //   setAmount(settingoption ? settingoption.amount : 0);
  // }, [settingoption]);
  const getDropdownButton = () => {
    if (active && webAdminForm === "menu")
      return (
        <DropdownMenu open={dropdownOpen} modal={false}>
          <DropdownMenuTrigger>
            <Button variant="app" size="xs" className="absolute bottom-0 right-0">
              <FcDataConfiguration
                className="w-3 h-3"
                onClick={(e) => {
                  setDropdownOpen((prev) => !prev);
                  e.stopPropagation();
                }}
              />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-editor-background text-editor-text font-sans">
            <DropdownMenuLabel className="flex justify-between">
              <div>{option.title}</div>
              <Button
                size="xs"
                variant="default"
                className="rounded-sm px-2"
                onClick={(e) => {
                  setDropdownOpen((prev) => !prev);
                  e.stopPropagation();
                }}>
                x
              </Button>
            </DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>
              <span className="mr-2">Position</span>
              <Button
                size="xs"
                variant="outline"
                className="mr-2 px-2 rounded-[2px]"
                disabled={option.order === 0}
                onClick={() => {
                  onChange && onChange({ ...option, order: option.order! - 1 });
                }}>
                -
              </Button>
              {option.order! + 1}
              <Button
                size="xs"
                variant="outline"
                className="ml-2 px-2 rounded-[2px]"
                disabled={option.order === choice.options.length - 1}
                onClick={() => {
                  onChange && onChange({ ...option, order: option.order! + 1 });
                }}>
                +
              </Button>
            </DropdownMenuItem>
            <DropdownMenuItem>
              <span className="mr-2">Taille</span>
              <Button
                size="xs"
                variant="outline"
                className="mr-2 px-2 rounded-[2px]"
                disabled={option.size === 0}
                onClick={() => {
                  onChange && onChange({ ...option, size: option.size! - 1 });
                }}>
                -
              </Button>
              {option.size!}%
              <Button
                size="xs"
                variant="outline"
                className="ml-2 px-2 rounded-[2px]"
                disabled={option.order === 100}
                onClick={() => {
                  onChange && onChange({ ...option, size: option.size! + 1 });
                }}>
                +
              </Button>
            </DropdownMenuItem>
            <DropdownMenuSeparator />

            <DropdownMenuItem
              onClick={() => {
                if (onRemove) onRemove(option.id);
              }}>
              <Button variant="destructive" size="xs" className="rounded-[2px]">
                <HiOutlineTrash className="" />
              </Button>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
  };
  if (multi)
    return (
      <div className="flex mb-1">
        <div className={`flex min-h-[40px] flex-grow `}>
          <div className="flex-grow flex items-center px-3 relative">
            <BasicContentEditable
              html={option.title!}
              editOn={webAdminForm === "menu"}
              onChange={(value) => {
                onChange && onChange({ ...option, title: value });
              }}
            />
            {webAdminForm === "menu" && getDropdownButton()}
          </div>
          <div className="flex items-center px-3">
            <BasicContentEditable
              html={"+" + formatPrice(item.price)}
              editOn={webAdminForm === "menu"}
              onChange={(value) => {
                onChange &&
                  onChange({ ...option, item: { ...item, price: getPriceAsNumber(value) } });
              }}
            />
          </div>
          <div className="flex items-center px-3 text-base">
            <Button
              variant="ghost"
              className="p-1"
              disabled={amount === 0}
              onClick={() => onAction(-1)}>
              <BsFileMinusFill className="w-7 h-7" />
            </Button>
            <span className="min-w-3">{amount}</span>
            <Button variant="ghost" className="p-1">
              <BsFilePlusFill className="w-7 h-7" onClick={() => onAction(1)} />
            </Button>
          </div>
        </div>
      </div>
    );
  return (
    <div
      className={`border-2 pt-0 pb-2 rounded relative
      ${settingoption ? "border-primary border-4" : "border-muted"} flex flex-col`}
      onClick={() => {
        if (!active) onAction(1);
      }}>
      {active && webAdminForm === "menu" && getDropdownButton()}
      <div className="pt-3 text-center text-sm">
        <BasicContentEditable
          html={option.title!}
          editOn={webAdminForm === "menu"}
          onChange={(value) => {
            onChange && onChange({ ...option, title: value });
          }}
        />
      </div>
      {img && img !== "" && (
        <div className="flex-grow">
          <img
            src={img}
            alt={option.title!}
            style={{
              width: (option.size !== null && option.size !== undefined ? option.size : 100) + "%",
              paddingTop:
                (100 - (option.size !== null && option.size !== undefined ? option.size : 100)) /
                  2 +
                "%",
            }}
            className="m-auto"
          />
        </div>
      )}

      <div className="text-center text-sm pt-1">
        <BasicContentEditable
          html={"+" + formatPrice(item.price)}
          editOn={webAdminForm === "menu"}
          onChange={(value) => {
            onChange && onChange({ ...option, item: { ...item, price: getPriceAsNumber(value) } });
          }}
        />
      </div>
    </div>
  );
}
