"use client";

import type { Dispatch, ReactNode } from "react";
import { createContext, useContext, useReducer } from "react";

interface WebUiType {
  displaySearch: boolean; // true : the interface is edited by a pro, false : the website is displayed to the public
  selectedItemId: string | null;
}

const WebUiContext = createContext<WebUiType>({
  displaySearch: false,
  selectedItemId: null,
});

const WebUiDispatchContext = createContext<Dispatch<WebUiActionType> | null>(null);

interface WebUiProviderProps {
  children: ReactNode;
}

export function WebUiProvider({ children }: WebUiProviderProps): ReactNode {
  const [webUi, dispatch] = useReducer(webUiReducer, {
    displaySearch: false,
    selectedItemId: null,
  });

  return (
    <WebUiContext.Provider value={webUi}>
      <WebUiDispatchContext.Provider value={dispatch}>{children}</WebUiDispatchContext.Provider>
    </WebUiContext.Provider>
  );
}

export function useWebUiContext() {
  return useContext(WebUiContext);
}

export function useWebUiDispatch() {
  const context = useContext(WebUiDispatchContext);
  if (context === null) throw new Error("useWebUiDispatch must be used within a WebUiProvider");
  return context;
}

interface SearchActionType {
  type: "displaySearchOn" | "displaySearchOff";
}
interface SelectItemActionType {
  type: "selectItem";
  selectedItemId: string | null;
}
type WebUiActionType = SearchActionType | SelectItemActionType;

function webUiReducer(state: WebUiType, action: WebUiActionType) {
  switch (action.type) {
    case "displaySearchOn":
      return { ...state, displaySearch: true };
    case "displaySearchOff":
      return { ...state, displaySearch: false };
    case "selectItem":
      return { ...state, selectedItemId: action.selectedItemId };
  }
}
