import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
import { Children } from 'react';

const lifButtonVariants = cva(
  "before:ease relative rounded-md text-sm font-medium ring-offset-background overflow-hidden shadow-2xl" +
  "transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12" +
  " before:rotate-6 before:bg-white before:opacity-10 before:duration-700 hover:before:-translate-x-40" +
  " focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2" +
  " disabled:pointer-events-none disabled:opacity-50",
	{
		variants: {
			variant: {
				default:
					"bg-primary text-primary-foreground hover:bg-primary/90",
				destructive:
					"bg-destructive text-destructive-foreground hover:bg-destructive/90",
				outline:
					"border border-input bg-background hover:bg-accent hover:text-accent-foreground",
				secondary:
					"bg-secondary text-secondary-foreground hover:bg-secondary/80",
				ghost: "hover:bg-accent hover:text-accent-foreground",
				link: "text-primary underline-offset-4 hover:underline",
			},
			size: {
				default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
				lg: "h-11 rounded-md px-8",
				icon: "h-10 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
			size: "default",
		},
	}
);

interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof lifButtonVariants> {
}

const LifButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, children, ...props }, ref) => {
		return (
      <button className={cn(lifButtonVariants({ variant, size, className }))}      
          ref={ref} {...props} type='button'>
          <span className="relative z-10">{children}</span>
      </button>
		);
	}
);
LifButton.displayName = "Button";

export { LifButton, lifButtonVariants };
