"use client";

import Order from "@/model/order";
import OrderLine from "@/model/order-line";
import OrderLineSetting from "@/model/order-line-setting";
import SettingOptionType from "@/model/setting-option-type";
import { ItemType } from "@lifizy/domain-next";
import { nanoid } from "nanoid";
import type { Dispatch, ReactNode } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { useImmerReducer } from "use-immer";
const initOrderValue = {
  order: {
    id: "",
    orderLines: [],
    price: 0,
  },
  currentOrderLine: null,
};

interface OrderManager {
  order: Order;
  currentOrderLine: OrderLine | null;
}

const OrderManagerContext = createContext<OrderManager>(initOrderValue);

const OrderDispatchContext = createContext<Dispatch<OrderActionType> | null>(null);

interface OrderProviderProps {
  children: ReactNode;
}

export function OrderProvider({ children }: OrderProviderProps): ReactNode {
  const [order, dispatch] = useImmerReducer(orderReducer, initOrderValue);

  return (
    <OrderManagerContext.Provider value={order}>
      <OrderDispatchContext.Provider value={dispatch}>{children}</OrderDispatchContext.Provider>
    </OrderManagerContext.Provider>
  );
}

export function useOrderManagerContext() {
  return useContext(OrderManagerContext);
}

export function useOrderManagerDispatch() {
  const context = useContext(OrderDispatchContext);
  if (context === null) throw new Error("useOrderDispatch must be used within a OrderProvider");
  return context;
}

export function useSettingOption(choiceId: string, optionId: string) {
  const { currentOrderLine } = useContext(OrderManagerContext);
  const [settingOption, setSettingOption] = useState<SettingOptionType | null>(null);
  useEffect(() => {
    if (!currentOrderLine) {
      setSettingOption(null);
      return;
    }
    //Check if a option is selected for this choice
    const setting = currentOrderLine.settings.find((s) => s.choiceId === choiceId);
    if (!setting) {
      setSettingOption(null);
      return;
    }
    const sOption = setting.options.find((c) => c.optionId === optionId);
    if (!sOption) {
      setSettingOption(null);
      return;
    }
    setSettingOption(sOption);
  }, [currentOrderLine, choiceId, optionId]);
  return settingOption;
}

export function useIsNextDisabled(choiceId: string) {
  const { currentOrderLine } = useContext(OrderManagerContext);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  useEffect(() => {
    if (!currentOrderLine) {
      setIsNextDisabled(false);
      return;
    }
    //Check if a option is selected for this choice
    const setting = currentOrderLine.settings.find((s) => s.choiceId === choiceId);
    if (setting && setting.options.length > 0) setIsNextDisabled(false);
    else setIsNextDisabled(true);
  }, [currentOrderLine, choiceId]);
  return isNextDisabled;
}

interface InitCurrentActionType {
  type: "initCurrent";
  item: ItemType;
  settings: OrderLineSetting[];
}
interface UpdateCurrentActionType {
  type: "updateCurrentSetting";
  choiceId: string;
  optionId: string;
  amount: number;
  unitPrice: number;
  label: string;
  multi: boolean;
}
interface RemoveActionType {
  type: "remove";
  orderLineId: string;
}
interface DirectActionType {
  type: "add" | "clear";
}
type OrderActionType =
  | InitCurrentActionType
  | RemoveActionType
  | DirectActionType
  | UpdateCurrentActionType;

function orderReducer(mng: OrderManager, action: OrderActionType) {
  switch (action.type) {
    case "add":
      if (mng.currentOrderLine) mng.order.orderLines.push(mng.currentOrderLine);
      mng.order.price = mng.order.orderLines.reduce((acc, ol) => acc + ol.price, 0);
      break;
    case "initCurrent":
      mng.currentOrderLine = {
        id: nanoid(),
        itemId: action.item.id!,
        amount: 1,
        price: action.item.price!,
        unitPrice: action.item.price!,
        itemPrice: action.item.price!,
        label: action.item.title!,
        settings: action.settings,
      };
      break;
    case "updateCurrentSetting":
      if (!mng.currentOrderLine) throw new Error("No current order line");
      // const choice = menuData.categories.flatMap((c) => c.choices).find((s) => s.id === action.choiceId);
      // if (!choice) throw new Error("No choice found");
      // const choiceOption = choice.options.find((c) => c.id === action.optionId);
      // if (!choiceOption) throw new Error("No option found");
      const setting = mng.currentOrderLine.settings.find((s) => s.choiceId === action.choiceId);
      console.log(JSON.stringify(mng));
      console.log(action);
      console.log("choiceId:", action.choiceId);
      console.log("find setting", setting);
      if (setting) {
        if (!action.multi) setting.options = []; //Reset options if not multi
        const option = setting.options.find((c) => c.optionId === action.optionId);
        if (option) {
          option.amount = action.amount;
          option.unitPrice = action.unitPrice;
          option.price = action.unitPrice * action.amount;
        } else
          setting.options.push({
            label: action.label,
            optionId: action.optionId,
            amount: action.amount,
            unitPrice: action.unitPrice,
            price: action.unitPrice * action.amount,
          });
        setting.price = setting.options.reduce((acc, c) => acc + c.price, 0);
      } else
        mng.currentOrderLine.settings.push({
          choiceId: action.choiceId,
          label: action.label,
          price: action.unitPrice,
          options: [
            {
              optionId: action.optionId,
              label: action.label,
              amount: action.amount,
              unitPrice: action.unitPrice,
              price: action.unitPrice * action.amount,
            },
          ],
        });
      //recalculate order line price
      mng.currentOrderLine.unitPrice =
        mng.currentOrderLine.itemPrice +
        mng.currentOrderLine.settings.reduce((acc, s) => acc + s.price, 0);
      mng.currentOrderLine.price = mng.currentOrderLine.unitPrice * mng.currentOrderLine.amount;
      break;
    case "remove":
      mng.order.orderLines = mng.order.orderLines.filter((ol) => ol.id !== action.orderLineId);
      mng.order.price = mng.order.orderLines.reduce((acc, ol) => acc + ol.price, 0);
      break;
    case "clear":
      return initOrderValue;
  }
}
